import React, { useEffect, useState } from 'react';
import Loading from 'shared/components/Loading';
import { generateViewerData } from './utils';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { useSnackbar } from 'notistack';
import { steps as initialSteps, tools } from './configs';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

import ControlArea from './components/ControlArea';
import StepsArea from './components/StepsArea';
import HelpModal from './components/HelpModal';
import CanvasView from './components/CanvasView';
import QuestionaryModal from './components/QuestionaryModal';
import ExplanationModal from './components/ExplanationModal';
import HeatmapModal from './components/HeatmapModal';

const TypeCasesPlay = ({ lesson, fullData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const viewerOptions = useSelector(state => state.viewerOptions);
  const [fetch, setFetch] = useState();
  const [activeCase, setActiveCase] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [course, setCourse] = useState(fullData);

  const [activeTool, setActiveTool] = useState({});
  const [fullScreen, setFullScreen] = useState(false);
  const [openHelpModal, setOpenHelpModal] = useState(false);

  const lessonCases = lesson && (lesson.cases || lesson.activeCases);

  const fullScreenChange = () => {
    document.addEventListener('fullscreenchange', e => {
      setFullScreen(!!document.fullscreenElement);
    });
  };

  const handelActiveToolChange = tool => {
    setActiveTool(tool);
  };

  const stepChangeHandler = async step => {
    const isModal = step === 9 || step === 10 || step === 11;
    if (isModal) setActiveTool({});
    if (!completedSteps.includes(step) && !isModal) {
      completedSteps.push(step);
    }
    setActiveStep(step);
  };

  const getCases = async () => {
    try {
      if (!lessonCases || !lessonCases.length) return;
      const { caseId, caseUniqueId } = lessonCases[activeCase];
      const endpoint = `/cases/getcasebyid/${caseUniqueId || caseId}?lut=1&dicomType=all`;
      const { data } = await Api.get(endpoint);
      const cases = generateViewerData(data.data, lesson);
      setCourse({ title: lesson.title, lesson, cases });
      if (!cases[0].completed_steps.includes(0)) {
        cases[0].completed_steps.push(0);
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onCaseChange = async value => {
    try {
      if (!course.cases[value]) {
        setFetch(true);
        const { caseId, caseUniqueId } = lessonCases[value];
        const endpoint = `/cases/getcasebyid/${caseUniqueId || caseId}?lut=1&dicomType=all`;
        const { data } = await Api.get(endpoint);
        const caseData = generateViewerData(data.data, lesson);
        const cases = [...course.cases, ...caseData];
        if (!cases[value].completed_steps.includes(0)) {
          cases[value].completed_steps.push(0);
        }
        setCourse({ ...course, cases });
        setActiveCase(value);
        setFetch(false);
      } else {
        setActiveCase(value);
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  useEffect(() => {
    fullScreenChange();
    if (!course) getCases();
    //eslint-disable-next-line
  }, []);

  if (!course || fetch) return <Loading className='mt-5' />;

  let steps = [...initialSteps];
  const selectedCase = lessonCases?.[activeCase];

  if (!!course.isOpened || !selectedCase?.hasHeatmap) {
    // remove questionary and heatmaps
    const excludeNames = course.isOpened
      ? ['question', 'heatmap']
      : !selectedCase?.hasHeatmap
      ? ['heatmap']
      : [];
    steps = steps.filter(item => !excludeNames.includes(item.name));
  }

  const isModal = steps[activeStep].type === 'modal';
  const caseViews = course.cases[activeCase].views;
  const completedSteps = course.cases[activeCase].completed_steps;
  const stepViews = steps[activeStep].views;
  const stepViewsData = Object.values(stepViews);
  const viewsCount = Object.values(stepViews).length;

  return (
    <div className='viewer d-flex flex-column flex-fill'>
      <ControlArea
        course={course}
        fullScreen={fullScreen}
        activeTool={activeTool}
        setActiveTool={handelActiveToolChange}
        history={history}
        viewerOptions={viewerOptions}
        tools={tools}
      />
      <div
        className={`viewer-area has-steps-and-header d-flex ${fullScreen ? 'fullscreen' : ''}`}
        key={`${activeCase}_${activeStep}`}
      >
        {!isModal &&
          stepViewsData.map((item, index) => (
            <CanvasView
              key={item.key + index}
              dataTomo={caseViews[`${item.key}_TOMO`]}
              data2D={caseViews[`${item.key}_2D`]}
              data3DQ={caseViews[`${item.key}_3DQ`]}
              step={item}
              activeTool={activeTool}
              setActiveTool={handelActiveToolChange}
              fullScreen={fullScreen}
              viewsCount={viewsCount}
              updateFinding={() => {}}
              viewerOptions={viewerOptions}
            />
          ))}
        {isModal && (
          <>
            {steps[activeStep].name === 'question' && (
              <QuestionaryModal
                lesson={lesson}
                changeStep={stepChangeHandler}
                completedSteps={completedSteps}
                step={steps[activeStep]}
                isOpened={course.isOpened}
                caseId={selectedCase.caseId}
              />
            )}
            {steps[activeStep].name === 'explanator' && (
              <ExplanationModal
                isDisabled={false}
                updateStep={() => {}}
                caseData={selectedCase}
                caseId={selectedCase.caseId}
                caseViews={caseViews}
              />
            )}
            {steps[activeStep].name === 'heatmap' && (
              <HeatmapModal
                isDisabled={completedSteps.length < steps[activeStep].disabled_no_active}
                caseId={selectedCase.caseId}
                lesson={lesson}
                caseViews={caseViews}
                isOpened={course.isOpened}
                updateStep={step => {
                  completedSteps.push(step);
                }}
              />
            )}
          </>
        )}
      </div>
      <StepsArea
        cases={fullData?.cases || lessonCases}
        steps={steps}
        completedSteps={completedSteps}
        activeCase={activeCase}
        changeActiveCase={onCaseChange}
        activeStep={activeStep}
        changeActiveStep={stepChangeHandler}
        setActiveStep={setActiveStep}
        onHelpModalOpen={setOpenHelpModal}
        history={history}
      />
      {openHelpModal && <HelpModal onModalClose={() => setOpenHelpModal(false)} />}
    </div>
  );
};

export default TypeCasesPlay;
