/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import moment from 'moment';
import { scrollToEl } from 'utils/appHelpers';
// import Icon1 from 'assets/home-icon-1.svg';
// import Icon2 from 'assets/home-icon-2.svg';
// import { IconArrowRight } from 'shared/components/Icons';
// import { useHistory } from 'react-router';
import FooterIcon1 from 'assets/events/location.svg';
import FooterIcon2 from 'assets/events/calendar.svg';
import FooterIcon3 from 'assets/events/speakers.svg';
// import FooterIcon4 from 'assets/events/seats.svg';
import Slider from 'react-slick';
// import { useSelector } from 'react-redux';

const sliderSettings = {
  arrows: false,
  dots: false,
  speed: 500,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplaySpeed: 7000,
  className: 'event-slide',
  autoplay: true,
};

const EventBanner = ({ event }) => {
  // const isAuth = useSelector(state => state.isAuthenticated);
  // const account = useSelector(state => state.account);
  // const history = useHistory();
  // const openUrl = url => {
  //   window.open(url, '_blank');
  // };

  // const onClickStart = () => {
  //   if (isAuth) {
  //     history.push(`/events/${event.id}/dashboard/allcourses`);
  //   } else {
  //     history.push(`/events/${event.id}/register`);
  //   }
  // };

  // const onClickAccessVip = () => {
  //   const isAlreadyVIP = account.userType !== 6;
  //   let win;
  //   if (isAlreadyVIP) {
  //     win = window.open('/allcourses', '_blank');
  //   } else {
  //     win = window.open('/register', '_blank');
  //   }
  //   win.focus();
  // };

  // const isExpired = moment(event.endDate).diff(new Date()) <= 0;

  return (
    <div className='event-banner'>
      <div className='container'>
        <div className='banner-body'>
          <div className='over-block d-flex flex-column justify-content-between w-100'>
            <div className='top-block d-flex align-items-center'>
              <ul className='m-0 p-0 d-flex align-items-center'>
                <li onClick={() => scrollToEl('aboutSection')}>About</li>
                <li onClick={() => scrollToEl('noncmeSection')}>Non-CME</li>
                <li onClick={() => scrollToEl('cmeSection')}>CME</li>
                <li onClick={() => scrollToEl('speakersSection')}>Faculty</li>
                {/* <li onClick={() => scrollToEl('programSection')}>Program</li> */}
              </ul>
            </div>
            <div className='center-block d-flex flex-column justify-content-end'>
              {/* <div
              className='link-block d-flex align-items-center justify-content-between'
              onClick={openUrl.bind(null, event.webinarUrl)}
            >
              <div className='d-flex align-items-center'>
                <img width='28' src={Icon1} alt='icon' className='mr-2' />
                <span>WEBINARS</span>
              </div>
              <IconArrowRight color='#fff' />
            </div>
            <div
              className='link-block d-flex align-items-center justify-content-between'
              onClick={openUrl.bind(null, event.liveEventsUrl)}
            >
              <div className='d-flex align-items-center'>
                <img width='28' src={Icon2} alt='icon' className='mr-2' />
                <span>LIVE EVENTS</span>
              </div>
              <IconArrowRight color='#fff' />
            </div> */}
              <div className='buttons-block'>
                {/* <button onClick={onClickStart} className='btn start-btn'>
                  Start
                </button> */}
                {/* {!!isAuth && (
                <button onClick={onClickAccessVip} className={`btn access-vip text-nowrap px-1`}>
                  Access ACE
                </button>
              )} */}
              </div>
            </div>
            <div className='footer-block d-flex align-items-center'>{event?.name}</div>
          </div>
          <Slider {...sliderSettings}>
            {event.banners.map(item => {
              return (
                <div key={item.id}>
                  <img height='555' src={item.bannerUrl} alt='banner' />
                </div>
              );
            })}
          </Slider>
        </div>
        <div className='banner-footer d-flex align-items-center'>
          <ul className='d-flex align-items-center justify-content-between p-0 m-0 w-100'>
            <li className='d-flex align-items-center'>
              <img src={FooterIcon1} alt='ic' />
              <div>
                <p className='title m-0'>Worldwide</p>
                <p className='m-0'>Online Event</p>
              </div>
            </li>
            <li className='d-flex align-items-center'>
              <img src={FooterIcon2} alt='ic' />
              <div>
                <p className='title m-0'>{moment(event.startDate).format('MM/DD/YYYY')}</p>
                <p className='m-0'>{moment(event.endDate).format('MM/DD/YYYY')}</p>
              </div>
            </li>
            <li className='d-flex align-items-center'>
              <img src={FooterIcon3} alt='ic' />
              <div>
                <p className='title m-0'>{event.programs.length} Programs</p>
                <p className='m-0'>{event.speakers.length} Faculty</p>
              </div>
            </li>
            {/* <li className='d-flex align-items-center'>
            <img src={FooterIcon4} alt='ic' />
            <div>
              <p className='title m-0'>
                {event.capacity < 1 ? 'No Limit' : `${event.capacity} Seats`}
              </p>
              <p className='m-0'>Hurry up, register!</p>
            </div>
          </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default EventBanner;
