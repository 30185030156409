import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { navigation, unAuthMarketingNavigation } from './configs';
import { logout } from '../../../Auth/actions/index';
import { Link, NavLink } from 'react-router-dom';
import SubMenu from './components/SubMenu';
// import SubMenuAuthors from './components/SubMenuAuthors';
import SubMenuCourses from './components/SubMenuCourses';
import SubMenuSubscriptions from './components/SubMenuSubscriptions';
import topLogo from 'assets/ACE-logo.svg';
import topLogoRSNA from 'assets/RSNA_logo.svg';
import { IconHome } from 'shared/components/Icons';
import { hasAccess, hasAccessAny } from 'utils/permissionHelper';
import { getEventId, getEventUrl } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { onGetEvents } from 'app/Events/actions';
import { useEffect } from 'react';
import placeholder from 'assets/avatar.png';
import { getCourses } from 'app/Main/routes/Courses/actions';

const SideBar = ({ account, logout, courses }) => {
  const eventId = getEventId();
  const dispatch = useDispatch();
  const hasSharedCase = sessionStorage.getItem('caseSharedToken');
  const [activeState, setActiveState] = useState(null);
  const events = useSelector(state => state.events);
  const user = useSelector(state => state.account);
  const event = events && eventId ? events[eventId] : null;
  const isVisitorUser = hasAccess('visitor_user');

  const userLastAssignedEntity =
    user?.lastAssignedEntity?.length && user.lastAssignedEntity[user.lastAssignedEntity.length - 1];

  const { pathname } = useLocation();

  const isMarketingView = pathname.startsWith('/marketing-courses');

  const isNavActive = (pathname, match) => {
    if (!match) return false;
    setActiveState(pathname);
    return true;
  };

  const handleLogout = async () => {
    await logout();
    dispatch(getCourses());
  };

  const getEventData = async id => {
    try {
      const { data } = await Api.get(`/events/event/${id}`);
      dispatch(onGetEvents({ ...events, [id]: data.data }));
    } catch (err) {}
  };

  const isEvent = hasAccess('event_user');
  const path = isEvent ? getEventUrl() : '';
  const homeUrl = isEvent ? getEventUrl(true) : '/home';

  useEffect(() => {
    if (eventId) getEventData(eventId);
    //eslint-disable-next-line
  }, []);

  const homeNav = {
    name: 'Case',
    path: `case/view/${hasSharedCase}`,
    icon: IconHome,
  };

  const navs = hasSharedCase ? [homeNav, ...navigation] : navigation;

  return (
    <>
      <div>
        <div className='info-part d-flex flex-column align-items-center text-center'>
          <Link to={homeUrl} className='mt-4'>
            <img src={isEvent ? topLogoRSNA : topLogo} width='80%' alt='topLogo' />
          </Link>
          {!account ||
            (isVisitorUser && (
              <div className='profile-info mt-4'>
                <div className='avatar m-auto'>
                  <img src={placeholder} alt='placeholder' />
                </div>
                <div className='mt-2'>
                  <Link className='link text-white mx-2' to={`${path}/login`}>
                    Sign In
                  </Link>
                  <Link className='link text-white mx-2' to={`${path}/register`}>
                    Sign Up
                  </Link>
                </div>
              </div>
            ))}
          {account && !isVisitorUser && (
            <div className='profile-info mt-4'>
              <Link to={`${path}/profile`}>
                <div className='avatar m-auto'>
                  <img src={account.imageUrl} alt={account.name} />
                </div>
                <span>
                  {account.firstName} {account.lastName}
                </span>
              </Link>
              <div role='button' tabIndex='-1' onClick={handleLogout} className='pointer log-out'>
                Log out
              </div>
            </div>
          )}
        </div>
        <div className='navigation mt-3'>
          <ul>
            {isEvent && event && (
              <li className='nav-item'>
                <div className='d-flex'>
                  <div className='d-flex col-2 mr-1 p-0 align-items-center'>
                    <IconHome color='#b5bef6' />
                  </div>
                  <NavLink to={homeUrl}>RSNA 2020</NavLink>
                </div>
              </li>
            )}
            {navs &&
              navs.map(nav => {
                const Icon = nav.icon;
                const isActive = activeState === nav.path;
                if (nav.hideIfPermission && hasAccessAny(nav.hideIfPermission)) return null;
                if (nav.permission && !hasAccess(nav.permission)) return null;
                if (nav.isProducts && !user?.lastAssignedEntity?.length) return null;
                return (
                  <li className={`nav-item ${isActive ? 'active' : ''}`} key={nav.name}>
                    <div className='d-flex'>
                      <div className='d-flex col-2 mr-1 p-0 align-items-center'>
                        <Icon color={isActive ? '#ffffff' : '#b5bef6'} />
                      </div>
                      <NavLink
                        to={
                          nav.isProducts
                            ? `/product/${userLastAssignedEntity.productPath}`
                            : `${path}/${nav.path}`
                        }
                        isActive={isNavActive.bind(this, nav.path)}
                      >
                        {nav.name}
                      </NavLink>
                    </div>
                    {/* {isActive && nav.name === 'Faculty' && <SubMenuAuthors authors={authors} />} */}
                    {isActive && nav.name === 'My Transcript' && (
                      <SubMenuCourses path={path} courses={courses} />
                    )}
                    {isActive && nav.name === 'Subscriptions' && (
                      <SubMenuSubscriptions path={path} courses={courses} />
                    )}
                    {isActive && nav.menu && <SubMenu path={path} menu={nav.menu} />}
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
      <div className='text-center text-white fz-14 p-3'>
        Global Medical Education Breast & Skeletal Health
      </div>
    </>
  );
};

const mapStateToProps = ({ account, authors, courses }) => ({
  account,
  authors,
  courses,
});

export default connect(
  mapStateToProps,
  { logout },
)(SideBar);
