import React, { useState, useRef } from 'react';
import EmptyView from '../EmptyView';
import { Document, Page, pdfjs } from 'react-pdf';
import { IconPdf } from 'shared/components/Icons';

const pdfVersion = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = pdfVersion;

const TypePDF = ({ lesson }) => {
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState([]);
  const pdfContainerRef = useRef();
  const scrollingOffset = useRef();
  const width = window.innerWidth - 230 - 310;

  const onDocumentLoadSuccess = document => {
    const { numPages } = document;
    setPages(new Array(numPages).fill(1));
  };

  const selectPage = index => {
    setTimeout(() => {
      const elementPDF = document.querySelector('.react-pdf__Document');
      if (elementPDF) elementPDF.scrollTop = 0;
      setPage(index + 1);
    }, 0);
  };

  const handlePdfScrolling = () => {
    if (pages.length < 2) return;
    const elementPDF = document.querySelector('.react-pdf__Document');
    if (!elementPDF) return;
    const { scrollHeight, scrollTop, clientHeight } = elementPDF;
    const scrolledTillEnd = scrollTop + clientHeight >= scrollHeight;

    setTimeout(() => {
      const nextCondition =
        scrollingOffset.current < scrollTop && scrolledTillEnd && page !== pages.length;
      const prevCondition = scrollingOffset.current > scrollTop && scrollTop === 0 && page !== 1;
      if (nextCondition) {
        elementPDF.scrollTop = 1;
        setPage(page + 1);
      } else if (prevCondition) {
        elementPDF.scrollTop = scrollHeight - clientHeight - 1;
        setPage(page - 1);
      }
      scrollingOffset.current = scrollTop;
    }, 0);
  };

  return (
    <>
      <div ref={pdfContainerRef} className='col p-0' onScroll={handlePdfScrolling} id='myElement'>
        {lesson.contentUrl && (
          <div className='video-area flex-1 d-flex justify-content-center h-100'>
            <Document
              file={lesson.contentUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              id='myElement2'
            >
              <Page width={width} pageNumber={page} id='myElement3' />
            </Document>
          </div>
        )}
        {!lesson.contentUrl && (
          <div className='video-area flex-1 d-flex justify-content-center h-100'>
            <EmptyView text='No PDF file specified.' Icon={IconPdf} />
          </div>
        )}
      </div>
      <div className='course-parts'>
        <p className='course-title'>{lesson.title} - Chapters</p>
        <div className='course-parts-lists'>
          {pages.map((item, index) => (
            <div
              onClick={() => selectPage(index)}
              role='button'
              tabIndex='-1'
              key={index}
              className={`part-item d-flex align-items-center px-3 ${
                index + 1 === page ? 'active' : ''
              }`}
            >
              <div>
                <p className='name'>Page N{index + 1}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default TypePDF;
