import React, { useEffect, useState } from 'react';
import InfoSection from './components/InfoSection';
import LessonsListSection from './components/LessonsListSection';
import TypeVideo from './components/TypeVIdeo';
import TypePDF from './components/TypePDF';
import TypeCases from './components/TypeCases';
import TypeImage from './components/TypeImage';
import TypeEvaluation from './components/TypeEvaluation';
import Loading from 'shared/components/Loading';
import TagsViewBlock from './components/TagsViewBlock';
import TypeCasesPlay from './components/TypeCasesPlay';
import EmptyView from './components/EmptyView';
import { IconSelectLesson } from 'shared/components/Icons';
import HeaderSection from './components/HeaderSection';

const viewsComponents = {
  1: TypeVideo,
  2: TypePDF,
  3: TypeCases,
  4: TypeImage,
};

const CoursePreview = ({ course }) => {
  const [isViewer, setIsViewer] = useState();
  const [activeLesson, setActiveLesson] = useState();

  const onCasePlayClick = () => {
    setIsViewer(true);
  };

  useEffect(() => {
    if (course && course.courseLessons) {
      setActiveLesson(course.courseLessons.length && course.courseLessons[0].lesson);
    }
  }, [course]);

  if (!course) return <Loading />;

  const LessonView = activeLesson && viewsComponents[activeLesson.contentType];
  const isFeedback = activeLesson && activeLesson.contentType === 5;
  const noAnyLesson = course.courseLessons?.length < 2 && isFeedback;

  if (noAnyLesson) {
    return (
      <div className='course-view flex-1 d-flex flex-column position-relative'>
        <HeaderSection course={course} />
        <div className='video-area body-section overflow-hidden d-flex justify-content-center mb-2'>
          <h3>Please add 1 lesson at least.</h3>
        </div>
      </div>
    );
  }

  if (isViewer) {
    return (
      <div className='course-view flex-1 d-flex flex-column position-relative'>
        <HeaderSection
          course={course}
          hasBack={true}
          onBackClick={() => {
            setIsViewer(false);
          }}
        />
        <TypeCasesPlay lesson={activeLesson} />
      </div>
    );
  }

  if (isFeedback) {
    return (
      <div className='course-view flex-1 d-flex flex-column position-relative'>
        <HeaderSection
          hideAuthors={!!course?.isMarketingMaterial}
          course={course}
          hasBack={true}
          onBackClick={() => {
            setActiveLesson(course.courseLessons.length && course.courseLessons[0].lesson);
          }}
        />
        <TypeEvaluation course={course} />
      </div>
    );
  }

  return (
    <div className='course-view flex-1 d-flex flex-column position-relative'>
      <HeaderSection course={course} />
      <div className='video-area body-section overflow-hidden d-flex bg-light-blue justify-content-center mb-2'>
        {!LessonView && <EmptyView text='Here will be selected lessons' Icon={IconSelectLesson} />}
        {!!LessonView && <LessonView lesson={activeLesson} onCasePlayClick={onCasePlayClick} />}
      </div>
      <div className='footer-section d-flex'>
        <div className='col courses-area p-0'>
          <LessonsListSection
            lessons={course.courseLessons}
            activeLesson={activeLesson}
            setActiveLesson={setActiveLesson}
          />
        </div>
        <div className='col info-area'>
          <InfoSection info={course.info} />
          <TagsViewBlock tags={course.courseTags} />
        </div>
      </div>
    </div>
  );
};

export default CoursePreview;
