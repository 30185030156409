/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Modal from 'react-modal';
import { confirmAlert } from 'react-confirm-alert';
import { useSnackbar } from 'notistack';
import InputGroup from 'shared/components/InputGroup';
import { Api } from 'utils/connectors';
import { IconKey, IconRight } from 'shared/components/Icons';
import { findCourseFirstLesson, getError } from 'utils/appHelpers';
// import keyImage from 'assets/keyimage.png';
import { getCourses } from 'app/Main/routes/Courses/actions';
import ButtonLoading from './ButtonLoading';

const SubscriptionModal = ({
  data,
  onModalClose,
  onSuccess,
  getCourses,
  isFellowCourse,
  radiologist8HourCourseId,
}) => {
  const userId = useSelector(item => item.account.id);
  const { enqueueSnackbar } = useSnackbar();
  const [fetching, setFetching] = useState(false);
  const history = useHistory();
  const keyReq = data && data.keyRequired;
  const [form, setForm] = useState({
    key: '',
    medicalLicenseNumber: '',
    npiNumber: '',
  });

  const subscribe = async () => {
    setFetching(true);
    try {
      if (isFellowCourse && !form.key.includes('FELLOW')) {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className='react-confirm-alert-body md'>
                <p>
                  This course is intended for Fellows only. Your code indicates you may be looking
                  for this course
                  <span
                    className='link-txt'
                    onClick={() => {
                      onModalClose();
                      if (radiologist8HourCourseId) {
                        history.push(`/allcourses/details/${radiologist8HourCourseId}`);
                      }
                      onClose();
                    }}
                  >
                    {' '}
                    Radiologists: 8-hour Tomo Training - Essentials of 3D Breast Tomosynthesis
                    Interpretation{' '}
                  </span>
                  . If you are looking for the Fellows course please enter a valid registration code
                  for the Fellows course.
                </p>
                <div className='react-confirm-alert-button-group'>
                  <button onClick={onClose}>Ok</button>
                </div>
              </div>
            );
          },
          className: 'md custom-alert-container',
        });
        return;
      }
      const res = await Api.post('/subscription/subscribe', {
        subscriptionId: data.id,
        uniqueKey: form.key,
        medicalLicenseNumber: form.medicalLicenseNumber,
        npiNumber: form.npiNumber,
        userId,
      });
      await getCourses();
      if (onSuccess) onSuccess(true);
      enqueueSnackbar(res.data.message, { variant: 'success' });
      onModalClose();
      if (!keyReq && data.courses && data.courses[0]) {
        const course = data.courses[0];
        course.status = 1;
        findCourseFirstLesson(course, history);
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetching(false);
    }
  };

  const onHandleForm = ({ target }, trim) => {
    const value = trim ? target.value.trim() : target.value;
    setForm({ ...form, [target.name]: value });
  };

  if (!data) return null;

  const formValid = form.key && form.npiNumber && form.medicalLicenseNumber;

  return (
    <Modal
      className='modal-default subscription-modal flex-column d-flex'
      isOpen={true}
      onRequestClose={onModalClose}
      contentLabel='Connection and device test'
    >
      <button className='button-times' onClick={onModalClose} />
      <img className='subscription-image' src={data.image} alt={data.name} />
      <h5 className='my-3'>{data.name}</h5>
      <div className='d-flex justify-content-between text-black-50 text-desc bold weight-400'>
        <span>Duration</span>
        <span>{data.time} hours</span>
      </div>
      <div className='d-flex justify-content-between text-black-50 text-desc bold weight-400'>
        <span>Parts</span>
        <span>{data.totalCourses} parts</span>
      </div>
      <div className='d-flex justify-content-between text-black-50 text-desc bold weight-400'>
        <span>Course Duration</span>
        <span>{data.days} days</span>
      </div>
      <div className='d-flex justify-content-between text-black-50 text-desc bold weight-400'>
        <span>Faculty</span>
        <span>{data.totalAuthors || data.authors.length} Faculty</span>
      </div>
      {!!keyReq && (
        <>
          <div className='password-input d-flex mt-3'>
            <div className='pr-3 pl-2 pass-icon mb-2'>
              <IconKey />
            </div>
            <InputGroup
              className='pl-3'
              type='text'
              name='key'
              value={form.key}
              placeholder='Registration Code'
              disabled={fetching}
              onChange={e => {
                onHandleForm(e, true);
              }}
            />
          </div>
          <div className='password-input d-flex mt-2'>
            <div className='pr-3 pl-2 pass-icon mb-2'>
              <IconKey />
            </div>
            <InputGroup
              className='pl-3'
              type='text'
              name='medicalLicenseNumber'
              value={form.medicalLicenseNumber}
              placeholder='Medical License Number'
              disabled={fetching}
              onChange={onHandleForm}
            />
          </div>
          <div className='password-input d-flex mt-2'>
            <div className='pr-3 pl-2 pass-icon mb-2'>
              <IconKey />
            </div>
            <InputGroup
              className='pl-3'
              type='text'
              name='npiNumber'
              value={form.npiNumber}
              placeholder='NPI number'
              disabled={fetching}
              onChange={onHandleForm}
            />
          </div>
        </>
      )}
      <div
        className={`align-items-center d-flex pt-3 ${
          keyReq ? 'justify-content-end' : 'justify-content-end mt-3 border-top'
        }`}
      >
        <button
          className='btn subscribe-btn p-0 d-flex align-items-center'
          onClick={subscribe}
          disabled={(keyReq && !formValid) || fetching}
        >
          {fetching ? (
            <ButtonLoading />
          ) : (
            <span>
              Enroll <IconRight color='#131f6b' className='ml-1' />{' '}
            </span>
          )}
        </button>
      </div>
    </Modal>
  );
};

export default connect(
  null,
  { getCourses },
)(SubscriptionModal);
