/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { memo, useState } from 'react';

const InputGroup = ({
  name,
  value,
  onChange,
  onBlur,
  placeholder,
  type,
  disabled,
  required,
  icon,
  rightIcon,
  eyeIcon,
  className,
  hasError,
  autoFocus,
}) => {
  const [isEyeActive, setIsEyeActive] = useState(false);

  const handleTogglePassInputType = () => setIsEyeActive(!isEyeActive);

  const inputType = type === 'password' && isEyeActive ? 'text' : type;

  return (
    <div
      className={`input-group ${className || ''} ${hasError ? 'has-error' : ''} ${
        !icon ? 'no-icon' : ''
      }`}
    >
      {icon && <div className='icon-area'>{icon}</div>}
      <input
        autoFocus={autoFocus}
        type={inputType}
        value={value}
        id={name}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        aria-describedby=''
        placeholder={placeholder}
        autoComplete='off'
        disabled={disabled}
        required={required}
        className={`flex-fill ${rightIcon && 'pr-26'}`}
      />
      {(rightIcon || eyeIcon) && (
        <div
          onClick={eyeIcon && handleTogglePassInputType}
          className={`right-icon-area ${eyeIcon && 'eye-icon-area'}`}
        >
          {rightIcon || eyeIcon}
          {isEyeActive && <div className='eye-active-line' />}
        </div>
      )}
    </div>
  );
};

export default memo(InputGroup);
