import { WithMouseFollowOrb, useMouseFollowOrb } from 'HOC/WithMouseFollowOrb';
import React from 'react';
import Button from 'shared/components/Button';

const Banner = props => {
  const { name, description, bannerVideo, bannerImg } = props;
  const canvasRef = useMouseFollowOrb();
  return (
    <div className='banner'>
      <div className='inner-block first-block'>
        <canvas className='canvas-elm' ref={canvasRef} style={{ width: '100%', height: '100%' }} />
        <div className='content'>
          <h1 className='title'>{name}</h1>
          <p className='regular-text max-row-5'>{description}</p>
          <Button className='btn-purple-filled'>See More</Button>
        </div>
      </div>
      <div className='inner-block second-block'>
        {bannerImg && <img className='img-cover' alt={name} src={bannerImg} />}
      </div>
    </div>
  );
};

export default WithMouseFollowOrb(Banner);
