import React from 'react';
import placeholder from 'assets/entity.svg';

const EntityInfoBlock = ({ entity }) => {
  return (
    <div className='entity-block d-flex align-items-center'>
      <div className='img-area'>
        <img src={entity?.imageUrl || placeholder} alt='entity' />
      </div>
      <div className='col'>
        <div className='d-flex align-items-center mb-2'>
          <p className='col-3'>Entity Name:</p>
          <h4 className='name'>{entity.name}</h4>
        </div>
        <div className='d-flex align-items-center'>
          <p className='col-3'>Entity Address:</p>
          <h4 className='address'>{entity.address}</h4>
        </div>
      </div>
    </div>
  );
};

export default EntityInfoBlock;
