import { HOST } from './host';
import twitter from 'assets/social-icons/twitter.svg';
import facebook from 'assets/social-icons/facebook.svg';
import linkedin from 'assets/social-icons/linkedin.svg';
import youtube from 'assets/social-icons/youtube.svg';
import instagram from 'assets/social-icons/instagram.svg';

export const PLATFORM = {
  learner: 1,
  admin: 2,
  hcb: 3,
};

export const LESSON_STATUSES = {
  inProgress: 1,
  notStarted: 2,
  completed: 3,
};

export const LESSON_TYPES = {
  quiz: 'quiz',
  video: 'video',
  image: 'image',
  pdf: 'pdf',
  case: 'case',
};

export const SOCIALS = [
  {
    img: twitter,
    url: 'https://twitter.com/Hologic',
  },
  {
    img: linkedin,
    url: 'https://www.linkedin.com/company/hologic',
  },
  {
    img: facebook,
    url: 'https://www.facebook.com/Hologic',
  },
  {
    img: youtube,
    url: 'https://www.youtube.com/@HologicInc',
  },
  {
    img: instagram,
    url: 'https://www.instagram.com/hologic/',
  },
];

export const COURSE_TYPES = {
  1: 'video',
  2: 'pdf',
  3: 'cases',
  4: 'image',
  5: 'feedback',
  6: 'quiz',
};

export const COURSE_TYPES_BY_TYPENAME = {
  video: 1,
  pdf: 2,
  cases: 3,
  image: 4,
  feedback: 5,
  quiz: 6,
};

export const LESSON_TYPES_BY_NUMBER = {
  1: 'video',
  2: 'pdf',
  3: 'cases',
  4: 'image',
  5: 'feedback',
  6: 'quiz',
};

export const API_RESPONSE_STATUSES = {
  Success: 0,
  Fail: 100,
  InvalidToken: 101,
  SessionExpired: 102,
  InvalidSessionUser: 103,
  NotActiveSession: 104,
  InvalidSession: 105,
  InvalidInvitationToken: 106,
  ExpiredInvitationToken: 107,
  ExpiredConfirmationCode: 108,
  EntityNotFound: 109,
  ProductPageNotFound: 110,
};

export const API_RESPONSE_STATUS_CODES = {
  Bad: 400,
  UnAuth: 401,
  Success: 200,
  CreateUpdate: 201,
};

export const DEFAULT_IMG_URLS = {
  lesson: `${HOST.API.CONTENT}/content/def_lesson.png `,
  course: `${HOST.API.CONTENT}/content/def_course.png `,
};
