/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';

export const SortableImgItem = props => {
  const {
    name,
    id,
    url,
    imageUrl,
    activeItemId,
    overItemId,
    size = 'medium',
    handleSelectItem = () => {},
    isSelected,
    isActiveDragging,
  } = props;
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      onClick={() => handleSelectItem(id)}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <div
        className={`sortable_img_item ${(isActiveDragging || isSelected) &&
          'dragging'} ${size} ${overItemId === id && 'ml_130'} ${activeItemId === id && 'active'}`}
      >
        {activeItemId !== id && <img src={url || imageUrl} alt={`uncategorized ${id}`} />}
      </div>
    </div>
  );
};
