import React, { useState } from 'react';
import InputGroup from 'shared/components/InputGroup';
import { countries, degrees, specialty, states } from '../configs';
import Select from 'shared/components/Select';
import Button from 'shared/components/Button';

const FormStep = ({ formSubmit, user, fetch, error, regCode, lastForm, isEventUser }) => {
  const intial = {
    fname: '',
    lname: '',
    email: '',
    institution: '',
    countryId: '',
    city: '',
    address: '',
    phone: '',
    zipCode: '',
    degree: '',
    specialty: '',
    prefferedNameOnCertificate: '',
    password: '',
    rpassword: '',
    state: '',
    ...user,
    ...lastForm,
  };
  const [info, setInfo] = useState(intial);

  const areAllRequiredFieldsFilled = () => {
    const requiredFields = [
      'fname',
      'lname',
      'degree',
      'specialty',
      'email',
      'password',
      'rpassword',
      'institution',
      'countryId',
      'city',
      'state',
      'zipCode',
      'address',
      'phone',
    ];
    return requiredFields.every(field => Boolean(info[field]));
  };

  const isCountryUs = info.countryId === '226';
  const passwordMatch = info.password === info.rpassword;

  const handleChange = ({ target }) => {
    const { name, value } = target;
    const tempAuth = { ...info };
    tempAuth[name] = value;
    setInfo(tempAuth);
  };

  const handleFormSubmit = e => {
    e.preventDefault();
    const body = {
      regCode,
      email: info.email,
      password: isEventUser ? undefined : info.password,
      firstname: info.fname,
      lastname: info.lname,
      usertype: 1,
      countryid: info.countryId,
      learnerprofile: {
        state: info.state,
        institution: info.institution,
        city: info.city,
        address: info.address,
        zipCode: info.zipCode,
        phone: info.phone,
        degree: info.degree,
        profession: info.specialty,
      },
    };
    if (info.prefferedNameOnCertificate) {
      body.learnerprofile.prefferedNameOnCertificate = info.prefferedNameOnCertificate;
    }
    formSubmit(body, info);
  };

  return (
    <form onSubmit={handleFormSubmit} className='mt-5'>
      <h6 className='color-blue mb-3 font-weight-normal'>Personal Information</h6>
      <div className='d-flex mb-4'>
        <InputGroup
          type='text'
          name='fname'
          onChange={handleChange}
          className='mr-3'
          placeholder='First Name*'
          value={info.fname}
          required
        />
        <InputGroup
          type='text'
          name='lname'
          className='ml-3'
          onChange={handleChange}
          placeholder='Last Name*'
          value={info.lname}
          required
        />
      </div>
      <div className='d-flex mb-4'>
        <Select
          label='Degree*'
          name='degree'
          parentClassName=''
          className='bottom_bordered mr-3'
          value={info.degree}
          items={degrees}
          emptyValueClassName='empty_gray_select'
          onChange={handleChange}
          displayProperty={'name'}
          required
        />
        <Select
          label='Specialty*'
          name='specialty'
          parentClassName='ml-3'
          className='bottom_bordered'
          emptyValueClassName='empty_gray_select'
          value={info.specialty}
          items={specialty}
          onChange={handleChange}
          required
          displayProperty={'name'}
        />
      </div>
      <div className='d-flex mb-4'>
        <InputGroup
          type='text'
          name='prefferedNameOnCertificate'
          onChange={handleChange}
          className=''
          placeholder='Customized Name on Certificate'
          value={info.prefferedNameOnCertificate}
        />
      </div>
      <div className='d-flex mb-4'>
        <InputGroup
          type='email'
          name='email'
          onChange={handleChange}
          className=''
          placeholder='Email*'
          value={info.email}
          disabled={user.email}
          required
        />
      </div>
      <div className='d-flex mb-4'>
        <InputGroup
          type='password'
          name='password'
          onChange={handleChange}
          className='mr-3'
          placeholder='Password'
          value={info.password}
          required
        />
        <InputGroup
          type='password'
          name='rpassword'
          onChange={handleChange}
          className='ml-3'
          placeholder='Confirm password'
          value={info.rpassword}
          required
        />
      </div>
      <h5 className='color-blue mb-3 font-weight-normal'>Institute Information</h5>
      <div className='d-flex mb-4'>
        <InputGroup
          type='text'
          name='institution'
          onChange={handleChange}
          className=''
          placeholder='Institute Name*'
          value={info.institution}
          required
        />
      </div>
      <div className='d-flex mb-4'>
        <Select
          label='Country*'
          name='countryId'
          parentClassName=''
          className='bottom_bordered mr-3'
          value={info.countryId}
          items={countries}
          emptyValueClassName='empty_gray_select'
          onChange={handleChange}
          displayProperty={'name'}
          required
        />
        <div className='d-flex mb-0'>
          <InputGroup
            type='text'
            name='city'
            onChange={handleChange}
            className=''
            placeholder='City*'
            value={info?.learnerProfile?.city}
            required
          />
        </div>
      </div>
      <div className='d-flex mb-4'>
        {isCountryUs ? (
          <Select
            label='State/Province*'
            name='state'
            value={info.state}
            items={states}
            onChange={handleChange}
            required
          />
        ) : (
          <InputGroup
            type='text'
            name='state'
            onChange={handleChange}
            placeholder='State/Province*'
            value={info.state}
            required
          />
        )}
        <InputGroup
          type='text'
          name='zipCode'
          onChange={handleChange}
          className='ml-3'
          placeholder='Zip Code*'
          value={info.zipCode}
          required
        />
      </div>
      <div className='d-flex mb-4'>
        <InputGroup
          type='text'
          name='address'
          onChange={handleChange}
          className=''
          placeholder='Address*'
          value={info.address}
          required
        />
      </div>
      <div className='d-flex mb-4'>
        <InputGroup
          type='number'
          name='phone'
          onChange={handleChange}
          className=''
          placeholder='Phone Number*'
          value={info.phone}
          required
        />
      </div>
      <div className='text-center'>
        {!passwordMatch && info.rpassword && <div className='error-text'>Passwords mismatch</div>}
        {error && <div className='error-text'>{error}</div>}
        <Button
          type='submit'
          disabled={!areAllRequiredFieldsFilled() || !passwordMatch || fetch}
          className='btn btn-blue-dark w-100'
        >
          Next
        </Button>
      </div>
    </form>
  );
};

export default FormStep;
